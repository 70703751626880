import React, { useState, useRef } from 'react';
import './SoundPlayer.css';

function SoundPlayer({ soundName, soundUrl }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div id="player-container">
      <div id="button-container" onClick={handlePlayPause}>
        {isPlaying ? 
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
            <circle cx="11" cy="11.925" r="11" fill="black"/>
            <rect x="7.5" y="6.92505" width="3" height="10" fill="white"/>
            <rect x="11.5" y="6.92505" width="3" height="10" fill="white"/>
          </svg>
        : 
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                <circle cx="11" cy="11.925" r="11" fill="black"/>
                <path d="M17 11.925L8 6.7289V17.1212L17 11.925Z" fill="white"/>
            </svg>
        }
      </div>
      <span><p>{soundName}</p></span>
      <audio ref={audioRef} src={soundUrl} onEnded={() => setIsPlaying(false)} />
    </div>
  );
}

export default SoundPlayer;
