import React, { useState } from 'react';
import './TextInput.css';

function TextInput({ label, placeholder, onChange, isMultiline, rows }) {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <div className="text-input">
      {label && <label className="text-input-label">{label}</label>}
      {isMultiline ? (
        <textarea
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          className="text-input-field"
          rows={rows}
        />
      ) : (
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          className="text-input-field"
        />
      )}
    </div>
  );
}

export default TextInput;
