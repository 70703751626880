import React, { useState } from 'react';
import './TextBlock.css';

function TextBlock({ text }) {
  const [isTextTruncated, setTextTruncated] = useState(true);

  return (
    <div>
      <p className={`text-block ${isTextTruncated ? 'truncated' : ''}`}>
        {text}
      </p>
      <p style={{color: '#60A9FF', cursor: 'pointer', marginTop: 4}} onClick={() => setTextTruncated(!isTextTruncated)}>
        {isTextTruncated ? 'More' : 'Less'}
      </p>
    </div>
  );
}

export default TextBlock;
