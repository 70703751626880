import React, { useState, useEffect } from 'react';
import './App.css';
import Stat from '../src/components/Stat/Stat';
import Dropdown from './components/Dropdown/Dropdown';
import response from './mockapi/response.json'; 
import BarChart from './components/BarChart/BarChart';
import TextBlock from './components/TextBlock/TextBlock';
import Slider from './components/Slider/Slider';
import TextInput from './components/TextInput/TextInput';
import SoundList from './components/SoundList/SoundList';
import Threshold from './components/Threshold/Threshold';
import AddSoundModal from './components/AddSoundModal/AddSoundModal';
import AddPersonaModal from './components/AddPersonaModal/AddPersonaModal';


function calculateDifferences(array1, array2) {
  if(array1 && array2) {
    return array1.map((value, index) => Math.abs(value - array2[index]));
  }
  return [];
}

function App() {
  const [selectedConfig, setSelectedConfig] = useState(response.configs[0]?.config);
  const [personaOptions, setPersonaOptions] = useState([]);
  const [selectedPersonaDescription1, setSelectedPersonaDescription1] = useState('');
  const [selectedPersonaDescription2, setSelectedPersonaDescription2] = useState('');
  const [selectedPersonaValues1, setSelectedPersonaValues1] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0
  ]);
  const [selectedPersonaValues2, setSelectedPersonaValues2] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0
  ]);
  const [selectedPersonaSounds1, setSelectedPersonaSounds1] = useState([]);
  const [selectedPersonaSounds2, setSelectedPersonaSounds2] = useState([]);
  const [selectedPersonaInstability1, setSelectedPersonaInstability1] = useState({});
  const [selectedPersonaInstability2, setSelectedPersonaInstability2] = useState({});
  const [newConfig, setNewConfig] = useState(true);
  const [selectedModel, setSelectedModel] = useState(null);
  const [isSoundModalOpen, setIsSoundModalOpen] = useState(false);
  const [isPersonaModalOpen, setIsPersonaModalOpen] = useState(false);

  const similarThreshold = selectedConfig.similarityThreshold;
  const communicableThreshold = selectedConfig.communicabilityThreshold;

  const differences = calculateDifferences(selectedPersonaValues1, selectedPersonaValues2);

  const totalBars = differences.length;

  const similarBars = differences.filter(value => value < similarThreshold).length;
  const communicableBars = differences.filter(value => value > communicableThreshold).length;

  const similarPercentage = ((similarBars / totalBars) * 100).toFixed(1);
  const communicablePercentage = ((communicableBars / totalBars) * 100).toFixed(1);

//   const personaOptions = response.configs.flatMap((config) => 
//   config.personas.map((persona) => ({
//     label: persona.name,
//     value: persona.id,
//   }))
// );


  const modelOptions = [
    { label: 'Option 1', value: '1' },
    { label: 'Option 2', value: '2' },
    { label: 'Option 3', value: '3' },
  ];

  const configOptions = response.configs.map((config) => ({
    label: config.config.name,
    value: config.config
  }));

  function getInstabilityLevel(instabilityMeasure) {
    let level;
    let color;
  
    if (instabilityMeasure < 0.15) {
      level = 'LOW';
      color = '#26E216';
    } else if (instabilityMeasure < 0.40) {
      level = 'MEDIUM';
      color = '#FFC907';
    } else {
      level = 'HIGH';
      color = '#FF8080';
    }
  
    return { level, color };
  }

  const handleSelect1 = (option) => {
    
    // Find the correct config first
    const selectedConfig = response.configs.find(config => {
      return config.personas.some(persona => persona.id === option.value);
    });
    
    // Then find the correct persona within that config
    let selectedPersona;
    if (selectedConfig) {
      selectedPersona = selectedConfig.personas.find(persona => persona.id === option.value);
    }
    
    if (selectedPersona) {
      setSelectedPersonaDescription1(selectedPersona.description);
      setSelectedPersonaValues1(selectedPersona.values);
      setSelectedPersonaSounds1(selectedPersona.sounds);
      
      const { level, color } = getInstabilityLevel(selectedPersona.instabilityMeasure);
      setSelectedPersonaInstability1({ level, color });
    }    
};

const handleSelect2 = (option) => {
  
  // Find the correct config first
  const selectedConfig = response.configs.find(config => {
    return config.personas.some(persona => persona.id === option.value);
  });
  
  // Then find the correct persona within that config
  let selectedPersona;
  if (selectedConfig) {
    selectedPersona = selectedConfig.personas.find(persona => persona.id === option.value);
  }
  
  if (selectedPersona) {
    setSelectedPersonaDescription2(selectedPersona.description);
    setSelectedPersonaValues2(selectedPersona.values);
    setSelectedPersonaSounds2(selectedPersona.sounds);
    
    const { level, color } = getInstabilityLevel(selectedPersona.instabilityMeasure);
    setSelectedPersonaInstability2({ level, color });
  }    
};


  const handleModelSelection = (option) => {
    setSelectedModel(option);
  };

  const handleConfigSelection = (option) => {
    setSelectedConfig(option.value);
  };

  console.log(response.configs[0]?.config)
  console.log(configOptions)
  
  useEffect(() => {
    console.log("useEffect triggered: selectedConfig =", selectedConfig);
    if (selectedConfig !== null) {
      const config = response.configs.find((config) => config.config.id === selectedConfig.id);
      console.log("Found config:", config);
      if (config) {
        setPersonaOptions(
          config.personas.map((persona) => ({
            label: persona.name,
            value: persona.id,
          }))
        );
      }
    } else {
      setPersonaOptions([]);
    }
  }, [selectedConfig, response.configs]);

  return (
    <div className="App">
      <main>
        <header>
          <h1>WORLDLINES</h1>
          <button className='button2' onClick={() => setIsPersonaModalOpen(true)}>+ PERSONA</button>
          <button className='button2' onClick={() => setIsSoundModalOpen(true)}>+ SOUND</button>
        </header>
        <div id="stats-bar">
          <Stat color="#26E216" value={`${similarPercentage}%`} label="SIMILARITY" />
          <Stat color="#FFC907" value={`${communicablePercentage}%`} label="COMMUNICABILITY" />
        </div>
        <div id="visualization-container">
          <Threshold similar={similarThreshold} communicable={communicableThreshold} />
          <div id="bar-chart">
            {differences.map((value, index) => (
              <div
                key={index}
                id="bar"
                style={{
                  height: `${value}%`,
                  minHeight: 2,
                  backgroundColor: value > communicableThreshold ? '#FFC907' : value < similarThreshold ? '#26E216' : '#D9D9D9', // Set your default color
                  transition: "height 0.3s",
                }}
              />
            ))}
          </div>
        </div>
        <div id="personas-container">
          <div id="persona">
            <Dropdown options={personaOptions} onSelect={handleSelect1} />
            {selectedPersonaDescription1 ? (
              <div id="persona-content">
                <div id="persona-stats">
                  <div id="instability">
                    <p>INSTABILITY</p>
                    <Stat color={selectedPersonaInstability1.color} value={selectedPersonaInstability1.level} />
                  </div>
                  <BarChart values={selectedPersonaValues1} />
                </div>
                <TextBlock text={selectedPersonaDescription1} />
                <SoundList sounds={selectedPersonaSounds1} />
              </div>
            ) : (
              <p>Please select a persona</p>
            )}
          </div>
          <div id="persona" style={{border: 'none'}}>
            <Dropdown options={personaOptions} onSelect={handleSelect2} />
            {selectedPersonaDescription2 ? (
              <div id="persona-content">
                <div id="persona-stats">
                  <div id="instability">
                    <p>INSTABILITY</p>
                    <Stat color={selectedPersonaInstability2.color} value={selectedPersonaInstability2.level} />
                  </div>
                  <BarChart values={selectedPersonaValues2} />
                </div>
                <TextBlock text={selectedPersonaDescription2} />
                <SoundList sounds={selectedPersonaSounds2} />
              </div>
            ) : (
              <p>Please select a persona</p>
            )}
          </div>
        </div>
      </main>

      <div id="control-panel">
        <div id="tabs">
          <div
            id="tab"
            className={newConfig ? "tab-selected" : "tab-unselected"}
            onClick={() => setNewConfig(true)}
          >
            <b style={{color: newConfig ? "#000" : "#A7A7A7"}}>NEW</b>
          </div>
          <div
            id="tab"
            className={!newConfig ? "tab-selected" : "tab-unselected"}
            onClick={() => setNewConfig(false)}
          >
            <b style={{color: !newConfig ? "#000" : "#A7A7A7"}}>SAVED</b>
          </div>
        </div>
        {newConfig ? (
          <div id="config-content">
            <TextInput placeholder="CONFIG NAME" label="NAME"/>
            <Dropdown options={modelOptions} onSelect={handleModelSelection} label="MODEL"/>
            <Slider min={100} max={16000} increment={100} label="SAMPLE RATE" units="HZ" />
            <Slider min={100} max={10000} increment={100} label="SAMPLE DURATION" units="MS" />
            <Slider min={1} max={60} increment={1} label="SAMPLING INTERVAL" units="MIN" />
            <Slider min={1} max={150} increment={1} label="TIMEFRAME" units="DAYS" />
            <Slider min={10} max={1000} increment={10} label="SIMILAR=TRUE" units="" />
            <Slider min={10} max={1000} increment={10} label="COMMUNICABLE=TRUE" units="" />
            <button className='button1'>RUN CONFIG</button>
          </div>
        ):(
          <div id="config-content">
            <Dropdown options={configOptions} onSelect={handleConfigSelection} label="CONFIG" defaultValue={selectedConfig.name}/>
            {selectedConfig ? (
              <div id="config-stat-list">
                <div id="config-stat">
                  <p>MODEL</p>
                  <b>{selectedConfig.model}</b>
                </div>
                <div id="config-stat">
                  <p>SAMPLE RATE</p>
                  <b>{selectedConfig.sampleRate} HZ</b>
                </div>
                <div id="config-stat">
                  <p>SAMPLE DURATION</p>
                  <b>{selectedConfig.sampleDuration} MS</b>
                </div>
                <div id="config-stat">
                  <p>SAMPLING INTERVAL</p>
                  <b>{selectedConfig.samplingInterval} S</b>
                </div>
                <div id="config-stat">
                  <p>TIMEFRAME</p>
                  <b>{selectedConfig.timeframe} DAYS</b>
                </div>
                <div id="config-stat">
                  <p>SIMILAR=TRUE</p>
                  <b>{selectedConfig.similarityThreshold}</b>
                </div>
                <div id="config-stat">
                  <p>COMMUNICABLE=TRUE</p>
                  <b>{selectedConfig.communicabilityThreshold}</b>
                </div>
              </div>
            ) : (
              <p>Please select a config</p>
            )}
          </div>
        )}
        <div id="status-bar">
          <b className="status">NO CONFIG RUNNING</b>
        </div>
      </div>
      <AddSoundModal isOpen={isSoundModalOpen} onClose={() => setIsSoundModalOpen(false)} />
      <AddPersonaModal isOpen={isPersonaModalOpen} onClose={() => setIsPersonaModalOpen(false)} />
    </div>
  );
}

export default App;
