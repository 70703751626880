import React from 'react';
import './BarChart.css';

function BarChart({ values }) {
  return (
    <div className="bar-chart">
      {values.map((value, index) => (
        <div
          key={index}
          className="bar"
          style={{
            height: `${value}%`,
          }}
        />
      ))}
    </div>
  );
}

export default BarChart;
