import React, { useState } from 'react';
import './AddSoundModal.css';
import TextInput from '../TextInput/TextInput';

function AddSoundModal({ isOpen, onClose }) {
  const [soundTitle, setSoundTitle] = useState('');
  const [soundLink, setSoundLink] = useState('');

  const handleSave = () => {
    // Handle save action here
    console.log('Sound title:', soundTitle);
    console.log('Sound link:', soundLink);
  };

  return (
    <div className={`modal-background ${isOpen ? 'open' : ''}`}>
      <div className="modal">
        <div id="title">
            <b>ADD SOUND</b>
            <svg onClick={onClose} cursor="pointer" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M4.75732 4.75732L13.2426 13.2426" stroke="#D9D9D9" stroke-width="2" stroke-linecap="square"/>
                <path d="M4.75732 13.2427L13.2426 4.75739" stroke="#D9D9D9" stroke-width="2" stroke-linecap="square"/>
            </svg>
        </div>
        <TextInput label="NAME" placeholder="SOUND NAME" onChange={(e) => setSoundTitle(e.target.value)}/>
        <TextInput label="LINK (.WAV)" placeholder="SOUND LINK" onChange={(e) => setSoundLink(e.target.value)}/>
        <button className="button1" onClick={handleSave}>SAVE</button>
      </div>
    </div>
  );
}

export default AddSoundModal;
