import React, { useState } from 'react';
import './AddPersonaModal.css';
import TextInput from '../TextInput/TextInput';

function AddPersonaModal({ isOpen, onClose }) {
  const [personaName, setPersonaName] = useState('');
  const [personaDescription, setPersonaDescription] = useState('');

  const handleSave = () => {
    // Handle save action here
    console.log('Persona name:', personaName);
    console.log('Persona description:', personaDescription);
  };

  return (
    <div className={`modal-background ${isOpen ? 'open' : ''}`}>
      <div className="persona-modal">
        <div id="persona-title">
            <b>ADD PERSONA</b>
            <svg onClick={onClose} cursor="pointer" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M4.75732 4.75732L13.2426 13.2426" stroke="#D9D9D9" stroke-width="2" stroke-linecap="square"/>
                <path d="M4.75732 13.2427L13.2426 4.75739" stroke="#D9D9D9" stroke-width="2" stroke-linecap="square"/>
            </svg>
        </div>
        <TextInput label="NAME" placeholder="PERSONA NAME" onChange={(e) => setPersonaName(e.target.value)} />
        <TextInput 
            label="DESCRIPTION" 
            placeholder="ADD DESCRIPTION" 
            onChange={(e) => setPersonaDescription(e.target.value)}
            isMultiline={true}
            rows={6}
        />
        <button style={{minWidth: '100%'}} className="button1" onClick={handleSave}>SAVE</button>
      </div>
    </div>
  );
}

export default AddPersonaModal;
