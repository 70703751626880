import React from 'react';
import './Stat.css';

function Stat({ color, value, label }) {
  return (
    <div className="stat">
        <div className="stat-indicator" style={{ backgroundColor: color }}></div>
        <span className="stat-number">{value}</span>
        <span className="stat-label">{label}</span>
    </div>
  );
}

export default Stat;
