import React, { useState, useEffect } from 'react';
import './Dropdown.css';

function Dropdown({ options, label, onSelect, defaultValue }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  
  useEffect(() => {
    if (defaultValue) {
      const defaultOption = options.find(option => option.label === defaultValue);
      console.log('defaultOption', defaultOption);
      setSelectedOption(defaultOption);
    }
  }, [defaultValue, options]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  console.log(options);

  return (
    <div className="dropdown"
      onMouseLeave={() => setIsOpen(false)}
      >
      <div className="dropdown-label">{label}</div>
      <div className="dropdown-control" onClick={() => setIsOpen((prev) => !prev)}>
        <div className="dropdown-header">
            {selectedOption ? selectedOption.label : 'SELECT'}
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none" className={`dropdown-arrow ${isOpen ? 'open' : ''}`}>
                <path d="M3.34314 7.00049L8.99999 12.6573L14.6568 7.00049" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round"/>
            </svg>
        </div>
        <div className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
            {options.map((option, index) => (
                <div key={index} className="dropdown-option" onClick={() => handleSelect(option)}>
                    {option.label}
                    {selectedOption?.value === option.value && <span className="dropdown-marker"/>}
                </div>
            ))} 
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
