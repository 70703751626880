import React from 'react';
import './Threshold.css';

function Threshold({ similar, communicable }) {

    const similarOffsetBottom = (similar / 2 + 3) + "%";
    const communicableOffsetBottom = (communicable / 2 + 3) + "%";

    const similarOffsetTop = -(similar / 2 + 4) + "%";
    const communicableOffsetTop = -(communicable / 2 + 3) + "%";

    return (
        <div id="threshold-box">
            <div id="lines-container" >
                <div id="label-container" style={{ top: communicableOffsetTop }}>
                    <p className='label-font'>COMMUNICABLE</p>
                    <svg width="100%" height="1">
                        <line x1="0" y1="0" x2="100%" y2="0" style={{ stroke: '#E4E4E4', strokeWidth: 2, strokeDasharray: '2,4' }} />
                    </svg>
                </div>
                <div id="label-container" style={{ top: similarOffsetTop }}>
                <p className='label-font'>SIMILAR</p>
                    <svg width="100%" height="1">
                        <line x1="0" y1="0" x2="100%" y2="0" style={{ stroke: '#E4E4E4', strokeWidth: 2, strokeDasharray: '2,4' }} />
                    </svg>
                </div>
                <div id="center-line" />
                <div id="label-container" style={{ top: similarOffsetBottom }}>
                    <svg width="100%" height="1">
                        <line x1="0" y1="0" x2="100%" y2="0" style={{ stroke: '#E4E4E4', strokeWidth: 2, strokeDasharray: '2,4' }} />
                    </svg>
                </div>
                <div id="label-container" style={{ top: communicableOffsetBottom }}>
                    <svg width="100%" height="1">
                        <line x1="0" y1="0" x2="100%" y2="0" style={{ stroke: '#E4E4E4', strokeWidth: 2, strokeDasharray: '2,4' }} />
                    </svg>
                </div>
            </div>
        </div>
    );
  }
  
export default Threshold;
