import React from 'react';
import './SoundList.css';
import SoundPlayer from '../SoundPlayer/SoundPlayer';

function SoundList({ sounds }) {
    return (
      <div id="list-container" >
        <b>SOUNDS</b>
        <ul style={{ 
            listStyleType: 'none', 
            padding: 0, 
            margin: 0, 
            display: 'flex', 
            flexDirection: 'column', 
            gap: '10px', 
            width: '100%', 
            height: '100%',
            overflowY: 'auto',  // Enabled scrolling
            boxSizing: 'border-box' 
        }}>
        {sounds.map((sound, index) => (
            <li key={index} style={{ listStyleType: 'none', boxSizing: 'border-box', padding: 0, margin: 0, height: '100%', }}>
            <SoundPlayer soundName={sound.title} soundUrl={sound.url} />
            </li>
        ))}
        </ul>
      </div>
    );
  }
  

export default SoundList;