import React, { useState } from 'react';
import './Slider.css';

function Slider({ min, max, increment, label, units }) {
  const [value, setValue] = useState((min + max) / 2);

  return (
    <div id="container">
      <div id="info">
        <label id="text" style={{ marginBottom: '10px' }}>{label}</label>
        <div id="text">{value} {units}</div>
      </div>
      <input
        type="range"
        min={min}
        max={max}
        step={increment}
        value={value}
        onChange={(e) => setValue(Number(e.target.value))}
      />
    </div>
  );
}

export default Slider;
